// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-analog-index-js": () => import("./../../../src/pages/analog/index.js" /* webpackChunkName: "component---src-pages-analog-index-js" */),
  "component---src-pages-digital-js": () => import("./../../../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-psychology-architecture-js": () => import("./../../../src/pages/psychology_architecture.js" /* webpackChunkName: "component---src-pages-psychology-architecture-js" */),
  "component---src-templates-analogue-project-details-js": () => import("./../../../src/templates/analogue-project-details.js" /* webpackChunkName: "component---src-templates-analogue-project-details-js" */),
  "component---src-templates-digital-project-details-js": () => import("./../../../src/templates/digital-project-details.js" /* webpackChunkName: "component---src-templates-digital-project-details-js" */),
  "component---src-templates-psychology-architecture-project-details-js": () => import("./../../../src/templates/psychology-architecture-project-details.js" /* webpackChunkName: "component---src-templates-psychology-architecture-project-details-js" */)
}

